.Rcard {
    border-left: 2px solid red !important;
    border-right: 2px solid #607d8b !important;
    /* border-top-left-radius: 0px;
    border-bottom-right-radius: 0px; */
    border-radius: 0px !important;
    border-style: solid !important;
    border-image: linear-gradient( to bottom, #607d8b, red ) 1 100% !important;
}

.ReferralTxt {
    font-family: 'Uchen', serif;
    line-height: 20px;
    color: #0050af !important;
    /* color: #23639a !important; */
}
